@media screen and (min-width: 1024px){
    .featured-desktop{
        display: block;
    }
    .featured-mobile{
        display: none;
    }
}

/* MOBILE */
@media screen and (max-width: 1024px){
    .featured-desktop{
        display: none;
    }
    .featured-mobile{
        display: block;
    }
}
