.banner{
    background-color:lightgrey;
}
.banner h1{

    font-family: 'Meddon', cursive;
}
.banner img{
    height: 40em;
    width: 100%;
    object-fit: cover;
    object-position: 10% 60%;
    /* min-width: 100%; */
}
.image-text{
    color: white;
    font-family: 'Yellowtail', cursive;
}

@media screen and (max-width: 600px) {
    .banner img{
        height: 30em;
        width: 100%;
        object-fit: cover;
        object-position: 40% 60%;
    }
}